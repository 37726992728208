function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function (obj) {
      return typeof obj;
    };
  } else {
    _typeof = function (obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread2(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(source, true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(source).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

/* global FS */
var camelCase = require('camelcase');

var defaultConfig = {
  org: '',
  debug: false
  /**
   * FullStory Analytics plugin
   * @link https://getanalytics.io/plugins/fullstory/
   * @link https://help.fullstory.com/hc/en-us/sections/360003732794-JavaScript-API
   * @param {object} pluginConfig - Plugin settings
   * @param {string} pluginConfig.org - FullStory account's `org` ID. The `_fs_org` value in settings.
   * @return {object} Analytics plugin
   * @example
   *
   * fullStoryPlugin({
   *   org: 'your-org-name'
   * })
   */

};

function fullStoryPlugin$1() {
  var pluginConfig = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var source = 'analytics';
  return {
    name: 'fullstory',
    config: _objectSpread2({}, defaultConfig, {}, pluginConfig),
    initialize: function initialize(_ref) {
      var config = _ref.config;

      if (!config.org) {
        throw new Error('No org name supplied for fullstory');
      }

      if (!scriptAlreadyLoaded()) {
        // Create script & append to DOM
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.crossOrigin = 'anonymous';
        script.src = "https://edge.fullstory.com/s/fs.js";
        /* script.addEventListener('load', () => {
          isLoaded = true
        }) */
        // Catch any errors while loading the script

        script.addEventListener('error', function () {
          throw new Error("Full story failed to load.");
        }); // On next tick, inject the script

        setTimeout(function () {
          var firstScript = document.getElementsByTagName('script')[0];
          firstScript.parentNode.insertBefore(script, firstScript);
        }, 0);
      }

      window._fs_debug = config.debug;
      window._fs_host = 'www.fullstory.com';
      window._fs_org = config.org;
      window._fs_namespace = 'FS'
      /* eslint-disable */
      ;

      (function (m, n, e, t, l, o, g, y) {
        if (e in m) {
          if (m.console && m.console.log) {
            m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
          }

          return;
        }

        g = m[e] = function (a, b, s) {
          g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
        };

        g.q = [];

        g.identify = function (i, v, s) {
          g(l, {
            uid: i
          }, s);
          if (v) g(l, v, s);
        };

        g.setUserVars = function (v, s) {
          g(l, v, s);
        };

        g.event = function (i, v, s) {
          g('event', {
            n: i,
            p: v
          }, s);
        };

        g.shutdown = function () {
          g("rec", !1);
        };

        g.restart = function () {
          g("rec", !0);
        };

        g.consent = function (a) {
          g("consent", !arguments.length || a);
        };

        g.identifyAccount = function (i, v) {
          o = 'account';
          v = v || {};
          v.acctId = i;
          g(o, v);
        };

        g.clearUserCookie = function () {};
      })(window, document, window['_fs_namespace'], 'script', 'user');
      /* eslint-enable */

    },
    // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users
    // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-Recording-custom-user-data
    identify: function identify(_ref2) {
      var payload = _ref2.payload,
          config = _ref2.config;
      var userId = payload.userId,
          anonymousId = payload.anonymousId,
          traits = payload.traits;
      if (typeof FS === 'undefined') return false;
      var userTraits = formatPayload(traits, true);

      if (userId) {
        FS.identify(userId, userTraits, source);
      } else {
        userTraits.analyticsAnonymousId_str = anonymousId;
        FS.setUserVars(userTraits, source);
      }
    },

    /* https://help.fullstory.com/hc/en-us/articles/360020623234 */
    track: function track(_ref3) {
      var payload = _ref3.payload,
          options = _ref3.options,
          config = _ref3.config;
      if (typeof FS === 'undefined') return false;
      var eventData = formatPayload(payload.properties);
      FS.event(payload.event, eventData, source);
    },
    loaded: function loaded() {
      return !!window.FS;
    }
  };
}
/* Full story formatting reqs https://help.fullstory.com/hc/en-us/articles/360020623234 */

var suffixes = ['str', 'int', 'date', 'real', 'bool', 'strs', 'ints', 'dates', 'reals', 'bools'];
function formatPayload(traits, ignoreReservedKeys) {
  return Object.keys(traits).reduce(function (acc, curr) {
    var value = traits[curr];

    if (ignoreReservedKeys && isSpecialKey(curr)) {
      acc[curr] = value;
      return acc;
    }
    /* Format values for fullstory */


    var hasSuffix = suffixes.find(function (suffix) {
      return curr.endsWith("_".concat(suffix));
    });

    if (hasSuffix) {
      var cleanKey = curr.replace("_".concat(hasSuffix), '');
      acc["".concat(camelCase(cleanKey), "_").concat(hasSuffix)] = value;
      return acc;
    } // If no suffix exists, add one


    var suffix = getSuffix(value);
    acc["".concat(camelCase(curr), "_").concat(suffix)] = value;
    return acc;
  }, {});
}
var specialKeys = ['displayName', 'email', 'uid', 'acctId', 'website'];

function isSpecialKey(key) {
  return specialKeys.includes(key);
}

function getSuffix(value) {
  if (Array.isArray(value)) {
    if (value.every(function (v) {
      return typeof v === 'string';
    })) {
      return 'strs';
    }

    if (value.every(function (v) {
      return typeof v === 'boolean';
    })) {
      return 'bools';
    }

    if (value.every(function (v) {
      return typeof v === 'number';
    })) {
      return isInt(value);
    }

    if (value.every(function (v) {
      return v instanceof Date;
    })) {
      return 'dates';
    }
  }

  if (value instanceof Date) {
    return 'date';
  }

  switch (_typeof(value)) {
    case 'string':
      return 'str';

    case 'boolean':
      return 'bool';

    case 'number':
      return isInt(value);

    default:
      return 'str';
  }
}

function isInt(value) {
  return value % 1 != 0 ? 'real' : 'int'; // eslint-disable-line
}

function scriptAlreadyLoaded() {
  var scripts = document.getElementsByTagName('script');
  return !!Object.keys(scripts).filter(function (key) {
    var src = scripts[key].src;
    return src.match(/fullstory\.com\/s\/fs/);
  }).length;
}

/* This module will shake out unused code and work in browser & node 🎉 */

var index = fullStoryPlugin$1;

export default index;
